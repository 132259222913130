.live-performance-container {
  min-height: calc(100vh - 110px);
  font-size: 0.8rem;

  .map-container {
    background-color: $opacity-black-6;

    svg {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      height: 100%;
    }
  }

  .region-container {
    min-width: 280px;
    min-height: inherit;
    background-color: $opacity-1;

    .region-wrapper {
      min-height: inherit;

      .card:not(:first-of-type) {
        margin-top: $spacer;
      }
    }

    .card {
      background-color: $white;
    }

    .card, .card-header {
      color: $black !important;
    }

    .card-header {
      border-bottom: 1px solid $medium-gray;
      padding: 0.5rem 1rem;
    }

    .card-title {
      margin: 0;
      font-weight: bold !important;
      text-transform: uppercase;
    }

    .league-container {
      background-color: $opacity-black-3;
      height: 120px;

      .card:not(:first-of-type) {
        margin-left: $spacer;
      }

      .card-header {
        padding: 0;
        border-bottom: 1px solid $white;

        .card-title {
          border-left: 1px solid $white;
          margin: 0;
        }

        .card-header-icon {

        }

        .card-title, .card-header-icon {
          padding: 0.5rem;
        }
      }
    }

    .card {
      border: 1px solid $white;

      &.inverse {
        border: none;
        box-shadow: $box-shadow;
        color: $black !important;
      }

      .performance-card-value-title {
        font-size: 0.7rem;
      }
    }
  }
}

.region-transition-container {
  position: relative;
  width: 100%;
  height: 100%;

  .card-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    transition: all .75s ease-out;
    padding: 10px;
    will-change: transform, width, opacity;

    .card {
      position: relative;
    }
  }
}

.sales-transition-container {
  position: relative;
  width: 100%;
  height: 130px;

  .card-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    transition: all .75s ease-out;
    padding: 10px;
    will-change: transform, width, opacity;

    .card {
      position: relative;
      border: 1px solid $white;

      .card-header {
        padding: 0;
        border-bottom: 1px solid $white;

        .card-title {
          border-left: 1px solid $white;
          margin: 0;
        }

        .card-header-icon {

        }

        .card-title, .card-header-icon {
          padding: 0.5rem;
        }
      }
    }
  }
}

.animated-card {
  height: 100%;
}

.comparison-text {
  font-size: 80%;
}

.jvectormap-zoomin, .jvectormap-zoomout {
  display: none;
}

.footer-card-container {
  background-color: $opacity-1;
  padding: 10px 10px 10px 0;
}

.footer-card {
  background: $card-black-background;
  box-shadow: $box-shadow;
  border: $card-border-width solid $card-border-color;
  letter-spacing: 0.1rem;
  @include border-radius($card-border-radius);

  .title {
    border-bottom: $card-border-width solid $white;
    padding: 10px 20px;
  }

  .value {
    padding: 10px;
  }

  .value-block {
    border: $card-border-width solid $white;
    padding: 5px 10px;
    font-size: $h6-font-size;
  }
}

@media (max-width: 1024px) {
  .live-performance-container {
    font-size: 0.7rem;
  }

  .region-container {
    max-width: 200px;
    min-width: 210px!important;

    overflow-y: auto;
  }

  .sales-transition-container {
    height: 100px;
    overflow-x: auto;
  }
}

@media (min-width: 3500px) {
  .live-performance-container, .performance-card-value-title {
    font-size: 1rem !important;
  }

  .card-wrapper {
    margin-bottom: 30px !important;
  }

  .region-container {
    min-width: 400px !important;
  }

  .sales-transition-container {
    height: 160px;
  }
}
