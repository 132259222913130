%card-stats {
  hr {
    margin: 5px 15px;
  }
}

.card-stats, .filter-card {
  @extend %card-stats;

  padding-bottom: 0;

  .icon-section {
    height: 100%;
    width: 70px;
    border-radius: 10px 0 0 10px;
    @include diagonal-gradient($primary, $primary-states);

    i {
      font-size: 1.7em;
    }

    &.ep {
      @include diagonal-gradient($ep, $ep-states);
    }

    &.rp {
      @include diagonal-gradient($rp, $rp-states);
    }

    &.danger {
      @include diagonal-gradient($danger, $danger-states);
    }

    &.warning {
      @include diagonal-gradient($warning, $warning-states);
    }

    &.success {
      @include diagonal-gradient($success, $success-states);
    }

    &.info {
      @include diagonal-gradient($info, $info-states);
    }

    &.primary {
      @include diagonal-gradient($primary, $primary-states);
    }
  }

  .info-icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    @include diagonal-gradient($primary, $primary-states);

    i {
      color: $white;
      font-size: 1.7em;
      padding: 11px 0;

    }

    &.icon-danger {
      @include diagonal-gradient($danger, $danger-states);
    }

    &.icon-warning {
      @include diagonal-gradient($warning, $warning-states);
    }

    &.icon-success {
      @include diagonal-gradient($success, $success-states);
    }

    &.icon-info {
      @include diagonal-gradient($info, $info-states);
    }

    &.icon-primary {
      @include diagonal-gradient($primary, $primary-states);
    }
  }

  .numbers {
    text-align: right;

    h6.card-title {
      font-size: 0.8rem;
      color: $card-stats-gray;
    }

    .card-title {
      margin-top: 0;
    }

    .card-category {
      margin-bottom: 0;
      color: $dark-gray;
    }
  }

  .card-footer {
    padding: 0;
  }

  hr {
    border-color: lighten($black, 5%);
  }

  .stats {
    color: rgba($white, 0.6);
    margin: 10px 15px;
    font-size: 0.9em;
  }

  &.card-white {
    hr {
      border-color: rgba(43, 53, 83, 0.2)
    }
  }
}

.stacked-value-status-cards{
  .stacked-value-status-card:nth-child(1){
    .card{
      border-radius: 10px 10px 0 0;
    }
  }
  .stacked-value-status-card:nth-child(2){
    .card{
      border-radius: 0 0 10px 10px;
      padding-top: 0 !important;
    }
  }
}

@include media-breakpoint-down(sm) {
  .card-stats [class*="col-"] .statistics::after {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .numbers {
    text-align: left;
  }
}