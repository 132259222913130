.world-map {
  height: 420px;
  overflow: hidden;

  div {
    height: 420px;
  }

  svg {
    width: 100% !important;

    text {
      font-family: 'Inter', 'sans-serif';
      font-size: 12px;
    }
  }

  .jvectormap-container {
    position: relative;
  }

  .jvectormap-zoomin, .jvectormap-zoomout {
    background-color: #ffffff;
    border: 1px solid #efefef;
    cursor: pointer;
    display: inline;
    height: 28px;
    left: 5px;
    position: absolute;
    text-align: center;
    width: 28px;
  }

  .jvectormap-zoomin {
    bottom: 40px;
  }

  .jvectormap-zoomout {
    bottom: 5px;
  }
}

.jvectormap-tip {
  background-color: black;
  border-radius: 0.5rem;
  color: white;
  padding: 0.5rem;
  position: fixed;
}