& {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #fff;
  text-align: left;
  /*Add white background background: url('/InsuranceProviderDashboard/img/bg-pattern.svg'), linear-gradient(to right, #0A0A0A, #313131) !important;*/
  background-image: url("../../img/bg-pg-logo.jpg")!important;
  background: white;
  background-repeat: repeat, repeat;
  background-attachment: scroll, scroll;
  background-size: auto, auto;
  background-attachment: fixed !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.body {
  font-family: 'Montserrat', sans-serif;
  /*background: #F7F7F7;*/

}

@media (min-width: 1350px){
  .container {
    max-width: 1400px;
  }
}

.col-2{
  display: inline-block;
}
.summary-card-last
{
  border-radius: 0px 10px 10px 0px!important;
}
.status-bar-row{
  margin-right: -125px;
}
.status-bar-title-item{
  background-color: rgba(19, 19, 19, 0.45)!important;
  padding-top: 20px;
  font-size: 15px;
  text-align: right;
  font-size: 15px!important;
  border-radius: 0px 10px 10px 0px!important;
  /*margin-left: 1%;*/
}
.card-body-status{
  padding: 0px;
  height: 65px;
  padding-top: 5%;
}
.cart-title-summary{
  margin-top: 7%;
}
.summary-card-title-span{
  width: 115px;
  margin-top: 10px;
  display: block;
}
.cart-title-summary-long{
  margin-top: 3%;
}
.MuiInputBase-root .MuiInputBase-input{
  border-radius: 5px;
  margin-bottom: 5%;
  height: 30px;
  padding: 0px;
  margin:5px;
}
.rdt_TableHead{
  border: grey;
  border-style: solid;
  border-width: 0 0 1px 0;
  font-weight: bold;
}
.rdt_Pagination{
  background-color: rgba(211,211,211, 0.01)!important;
  justify-content: left!important;
  color: white!important;
}
.right-close-button{
  margin-left: 87%;
  cursor: pointer;
}
.search-datatable{
  vertical-align: top;
  display: inline-block;
  text-align: right;
}
.search-datatable-field{
  width: 55%;
  border-width: 1px;
  border-color: lightgray;
  border: solid;
}
.MuiInputBase-input.MuiInput-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}
.MuiInputBase-root input{
  border: solid!important;
  border-width: .5px!important;
  border-color: lightgray!important;
}
.MuiInputBase-root input:hover{
  border: solid!important;
  border-width: .5px!important;
  border-color: lightgray!important;
}

.MuiInputBase-root .MuiInput-underline{
  border-bottom: none!important;
}
.MuiInputBase-root.MuiInput-underline:before{
  content: ""!important;
  position: relative!important;
}

.col-lg .date-card{
  border: none;
}
.dt-icon-container .lt-date-icon{
  padding-top: 7px!important;
  margin-left: -7px;
  font-size: 13px!important;
}

.dt-icon-container{
  display: inline-block;
  /*border-width: 1px;*/
  /*border: solid;*/
  height: 30px;
  border-left-width: 0px;
  border-style: groove;
  border-radius: 0px 2px 2px 0px;
  /*margin-left: -22px;*/
}


.lt-date-icon{
  margin-left: -22px;
}

.MuiInputBase-input.MuiInput-input:hover{
  border: solid!important;
  border-width: .5px!important;
  border-color: lightgray!important;
  border-bottom: none;
}
.MuiInputBase-input.MuiInput-input{
  text-indent: 5px;
}
.embedded-title{
  font-weight: bold;
  font-size: 10px;
  margin-top: 10px;
  margin-left: 15px;
}
.fa-table-title{
  width: 2%;
  display: inline-block;
}
.map-icon-region{
  margin-left: 20%;
}
.table-title
{
  display: inline-block;
}
.close-button
{
  width: 86%;
  text-align: right;
  display: inline-block;
}
.date-picker-datatable{
  min-height: 1px;
  width: 60%;
  display: inline-block;
}
.jvectormap-zoomin{
  width: 10px;
  margin-top: -65%;
  margin-left:5px;
  cursor: pointer;
}
.jvectormap-zoomout{
  width: 10px;
  cursor: pointer;
  margin-left:5px;
}
.region-text-row{
  line-height: 5px;
}
.rdt_Table {
  background: transparent!important;
  background-color: transparent!important;
  height: auto!important;
}

.rdt_Table div{
  background-color: rgba(211,211,211, 0.01)!important;
}
.rdt_Table div div{
  color: white;
}

.bHaoss, .jnigHP, .rdt_TableHeader {
  background-color: transparent!important;
  min-height: 0!important;
}

.risk-coverage-bar-chart {
  display: inline-block;
}
.coverage-by-band-bar-chart{
  width: 100%;
}

.filter-element-pad{
  margin-top: 0.25rem !important;
}

.glyphicon-signal:before {
  content: "\e018";
}

.card-map{
  color: black;
}
.icon-container{
  color: white;
  height: 65px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-left: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-left: 6%;
  border-right: 1px solid #efefef21;
  border-width: 0px;
}
.custom-dropdown-toggle  {
  height: 33px!important;
  text-align: left!important;
  background-color: rgba(19, 19, 19, 0.45);
  border-color: #fff;
  color: #fff;
  font-size: 0.8rem;
}


body, a, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Montserrat", sans-serif !important;
}
/* Set padding to keep content from hitting the edges */

a {
  color: $white;
  text-decoration: none;

  &:hover {
    color: $gray-400;
    text-decoration: none;
  }
}

.body-content {
  padding-left: 15px;
  padding-right: 15px;
}

.ipd-line-chart{
  margin-left: -5%
}
/* Override the default bootstrap behavior where horizontal description lists  will truncate terms that are too long to fit in the left column  */


.dl-horizontal dt {
  white-space: normal;
}

.table td, .table th {
  border-top: 1px solid #3f3f3f;
  color:white;
}

.table thead th {
  border-bottom: none;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: rgba(19, 19, 19, 0.6);
  color: #FFF;
}
/* Set width on the form input elements since they're 100% wide by default */
input, select, textarea {
  max-width: 280px;
}
/* Dashboard specific */

.custom_dashboard .card {
  border-radius: 10px;
}

.custom_dashboard .card {
  min-height: 100px;
}

.summarybar__card-title {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.summarybar__card-item{
  padding: 0px;
  margin-left: 5%;
}
.summarybar__card-icon{
  margin-top: -5px;
}
.summarybar__card-value {
  font-size: 22px;
  font-weight: 500;
}

body .bg-dark {
  background-color: #0000006e !important;
}

body .menubutton {
  color: #A1A1A1;
  -webkit-transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
  font-size: 26px !important;
}

.menubutton:hover {
  color: #E1E1E1;
  background: transparent;
  cursor: pointer;
}

.placeholderTextLine {
  width: 97%;
  height: 1em;
  margin-top: 0px;
}
/*Drop downs*/

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #FFF !important;
}

.select2-container--default .select2-selection--single {
  background-color: transparent !important;
}

.select2-results__option {
  background: #FFF;
  color: #000;
}

.select2-results__option--highlighted {
  background: #000 !important;
  color: #FFF !important;
}

.select2 {
  width: 80%;
}

.select2-item {
  max-width: 275px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate button, .MuiInputBase-root input {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size:10px!important;
}

.card-small-text {
  font-size: 10px;
}

.truncate .MuiInputBase-root input, .truncate button{
  font-size: 10px!important;
}

.card-medium-text {
  font-size: 10px;
  color: white;
  font-weight: normal;
}

.date-medium-text {
  font-size: x-small;
}

.card-dark-background {
  background: rgba(0, 0, 0, 0.45) !important;
  background-color: rgba(0, 0, 0, 0.45) !important;
}

/*****NAVBAR*****/

.black-navbar{
  background: #0000006e;
  height:63px;
}

.navbar-brand{
  position:absolute;
  left:35px;
}
.navbar-brand img {
  width:80px;
}
.summary-card{
  background-color: rgba(19, 19, 19, 0.45)!important;
  border-radius: 0px;
  border: gray;
  border-style: solid;
  border-width: 0px;
  border-left-width: 1px;
}

.card-seethrough-background {
  background: rgba(0, 0, 0, 0);
}

.p-6 {
  padding: 6px;
}

.no-right-pad {
  padding-right: 0px;
}

.chart-pl-rem {
  padding-left: 1.2rem
}

.summary-tooltip {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 999;
  font-size: 14px;
}

.region-tooltip {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 999;
  font-size: 14px;
}

.chart-tooltip {
  position: absolute;
  top: 5px;
  right: 25px;
  z-index: 999;
  font-size: 10px;
}

.container-fluid-sm-left {
  width: 100%;
  margin-left: auto;
  padding-left: 1.5rem
}

.container-fluid-sm-right {
  width: 100%;
  margin-left: auto;
  padding-right: 1.1rem;
}

.sidebar_open_btn {
  position: fixed;
  left: 0;
  background-color: #ffffff;
  color: #000000;
  border-radius: 0px 6px 6px 0px;
  padding: 6px 8px 6px 8px;
  cursor: pointer;
  top: 90px;
  z-index: 9;
}

.select2-styling,
.select2-styling:hover,
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  background: #000;
  border-color: white;
}

.btn-secondary {
  background-color:transparent!important;
}

input {
  border-color: #6c757d;
  border:1px solid #6c757d;
}

.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 96px;
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  font-size: 18px;
}

.sidenav ul {
  list-style-type: none;
}

.sidenav a {
  padding: 8px;
  text-decoration: none;
  color: #FFF;
  transition: 0.3s;
  width: 200px;
}

.sidenav fa {
  float: left;
  opacity: 0.4;
}

.sidenav a {
  float: left;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

#main {
  transition: margin-left .5s;
  padding: 20px;
}

.recharts-cartesian-grid-vertical, .yAxis .recharts-cartesian-axis-line, .recharts-cartesian-axis-tick-line {
  visibility: hidden;
}

.jvectormap-tip{
  color:white;
  background-color:black;
  position: fixed;
  padding: 5px;
}

.graphWrapper {
  text-transform:uppercase;
  color: white;
  font-size: 10px;
  /*width: 100%;*/
  background-color: rgba(19, 19, 19, 0.45);
  border-radius: 10px;
  position: relative;
}

.custom-tooltip{
  background-color: white;
  color: #d12655;
  min-width: 50px;
  min-height: 50px;
  padding: 5px;
}
.custom-tooltip .label{
  margin-top: 33%!important;
}
.lgkeMn{
  color: white!important;
}
.rdt_TableBody div.rdt_TableRow:nth-child(odd){
  background-color: rgba(51, 51, 51, 0.3)!important;
}

.rdt_TableRow, .animated-card {
  i {
    &.up {
      color: $success;
    }
    &.down {
      color: $danger-states;
    }
    &.same {
      color: $yellow
    }
  }
}

.no-coloured-cell-padding {
  .rdt_TableCell {
    padding: 0;
  }
}

.rdt_TableRow {
  .rdt_StackedCell {
    padding: 10px;
    display: flex;
    justify-content: center;

    &:nth-child(odd) {
      border-bottom: 1px solid rgba(81,81,81,1);
    }

    .warning {
      background-color: $warning!important;
      color: $black;
      margin: -10px;
      padding: 10px;
    }

    .danger {
      background-color: $danger!important;
      color: $white;
      margin: -10px;
      padding: 10px;
    }

    .info {
      background-color: $info!important;
      color: $white;
      margin: -10px;
      padding: 10px;
    }
  }

  div.warning {
    background-color: $warning!important;
    color: $white;
  }
  div.danger {
    background-color: $danger!important;
    color: $white;
  }
  div.info {
    background-color: $info!important;
    color: $white;
  }
  div.success {
    background-color: $success!important;
    color: $white;
  }
  div.inactive {
    color: $default-opacity;
  }
}

.mapLoading {
  //background: url("../assets/PG_Loader.gif") no-repeat center center;
  background-size: cover;
}

.graphHeader {
  text-transform: uppercase!important;
  border-radius: 10px;
}
.graphWrapper .scrollbar-container{
  height: 100%!important;
}
.graphWrapper .graphLoading {
  padding: 115px 0;
  //background: url("../assets/PG_Loader.gif") no-repeat center center;
  background-size: cover;
}

.graphWrapper .loadingShim {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  //background: rgba(0, 0, 0, 0.7) url("../assets/PG_Loader.gif") no-repeat center center;
  background-size: contain;
}

.graphHeader {
  background-color: rgba(0, 0, 0, 0.45);
  color:white;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase!important;
  border-radius: 5px 5px 0px 0px;
  height: 25px;
}

.graphWrapper .height200 {
  min-height: 200px;
}

.jvectormap-container {
  height:100%;
  width:100%;
}
.region-filler{
  margin-top:15px;
}
.chart-header-center{
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
}
.region-tool-tip{
  text-align: right;
  margin-right:5px;
}
.map-table{
  border: 1px;
  border-style: solid;
  border-color: lightgray;
}
.ep-gray-borders{
  border-color: lightgray!important;
  border-width: thin!important;
}
.map-icon-section{
  border-left-style: hidden!important;
  border-top-style: hidden!important;
  border-color: lightgray!important;
  border-width: thin!important;
}
/* Toggle buttons */
.custom_toggle_btns .custom_toggle_btn_wrapper .checkbox-ios {
  margin-bottom: 0;
  margin-right: 5px;
}

.custom_toggle_btns .Elements_root__1k3P4 .switch input {
  display: none;
}

.custom_toggle_btns .custom_toggle_btn_wrapper .Elements_root__1k3P4 .switch i::before {
  width: 20px;
  height: 15px;
  padding: 1px;
  border-radius: 5px;
  margin: 3px;
}

.Elements_root__1k3P4 .switch i {
  border-radius: 5px;
}

.Elements_root__1k3P4 .switch :not(:checked) + i {
  background: #808080 !important;
}

.Elements_root__1k3P4 .switch i {
  display: inline-block;
  cursor: pointer;
  padding-right: 20px;
  transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  border-radius: 4px;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
  margin: 0;
}

.Elements_root__1k3P4 .switch i::before {
  display: block;
  content: '';
  width: 20px;
  height: 15px;
  padding: 1px;
  border-radius: 4px;
  background: white;
  margin: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.Elements_root__1k3P4 .switch :checked + i {
  padding-right: 0;
  padding-left: 20px;
  background: #64bd63;
}

.toggle-align {
  justify-content: center;
  display: inline-flex;
}

.table-bordered {
  border: none;
}

.small-col {
  width: 3.5rem;
}
html body .graphWrapper .rdt_TableHead {
  background-color: transparent!important;
}
html body .graphWrapper .rdt_TableHeader {
  font-weight: bold;
  background-color: transparent;
  min-height: 0px;
}

html body .graphWrapper .rdt_TableHeadRow {
  font-weight: bold;
}
.rdt_TableHeadRow div{
  font-weight: bold!important;
}
.rdt_Table{
  background-color: transparent;
}

.table-bordered th,
.table-bordered td {
  border: none;
}

/* Centre the search box within the drop down */
.dropdown-menu input {
  margin: 0 20px;
}

/* Remove the glow around the toggle button */
body .toggleButton .react-toggle-thumb {
  box-shadow: none;
}

.react-toggle--checked.rp .react-toggle-track {
  background: #e178ef !important;
}

.react-toggle--checked.rp .react-toggle-track:hover {
  opacity: 0.8;
}

.react-toggle--checked.ep .react-toggle-track {
  background: #25d8b9 !important;
}

.react-toggle--checked.ep .react-toggle-track:hover {
  opacity: 0.8;
}

body .react-toggle--checked .react-toggle-thumb {
  border-color: initial;
}

/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #333;
  opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  -webkit-transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

/* Override the styles on the DataTable component styling that clash with the perfect scrollbar */
.fWHyCt {
  width: fit-content !important;
  min-width: 100% !important;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

.scrollbar-container {
  position: relative;
  height: auto;
}

.upcomingEvents .scrollbar-container, .fHeight .scrollbar-container {
  height: 550px;
}

.scrollbar-container > div {
  overflow-x: initial;
}

/*# sourceMappingURL=styles.css.map*/
.pointSixEm {
  font-size: 0.6rem;
}

.onePointFourEm {
  font-size: 1.4rem;
}

.card {
  background-color: rgba(0, 0, 0, 0.45);
  color: #fff !important;
  border-radius: 10px;
}

.jvectormap-container {
  background-color:transparent!important;
}

.border-bottom {
  border:none!important;
}

.pt-2 {
  font-size: 20px!important;
  padding: 18px 1px 1px 1px!important;

}

.p-2 {
  font-size: 20px!important;
  padding: 18px 1px 1px 1px!important;
}
.p-6 {
  font-size:10px!important;
}

.pl-3 {
  font-size:20px;
}


.table td, .table th {
  font-size:10px;
}
.h-auto.w-auto.form-control{
  color: black!important;
}
.gIMaKV, .hvxsQi, .dGqwdB, .kwRiGp, .cTEQON, .jHsXUi, .cxtWCz,.icdHOq, .gVCvbY  {
  color:#fff!important;
  background-color:transparent!important;
  font-size:10px!important;

}

.btn-sm {
  font-size:10px!important;
}

.MuiInputBase-input {
  margin:5px;
}

.graphWrapper .scrollbar-container {
  padding: 10px;
}


.rdt_Table div div {
  font-size: 10px; }

.map-table, .map-table td, .map-table th {
  border:none!important;
}

.tableDatePicker {
  float: left;
  margin-right: 30px;
  position: relative;
  top: 5px;
}

.double-height {
  padding: 0.6rem;
}

.graphWrapper .card{
  background:none !important;
  border:none;
}
.graphWrapper .card .icon-container {
  height:auto;
}
.scrollXOverflow {
  overflow-x: scroll;
  width:100%;
}
.scrollRechart{
  width:1800px;
}

select.gCcqTE option {
  color: black;
}

.inactive {
  color: #6c757d!important;
  text-decoration: none;
}

.active {
  color: #ffffff !important;
  text-decoration: none;
}

.inactive i{
  color: #6c757d!important;
  text-decoration: none;
}

.active i{
  color: #ffffff !important;
  text-decoration: none;
}

.text-success {
  color: $success;
}

.text-error {
  color: $danger;
}

.text-warning {
  color: $warning;
}
