// Color system

$white: #ffffff !default;
$gray-100: #f6f9fc !default;
$gray-200: #e9ecef !default;
$gray-300: #e3e3e3 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default; // Line footer color
$gray-700: #525f7f !default; // Line p color
$gray-800: #32325d !default; // Line heading color
$gray-900: #212529 !default;
$link-disabled: #666666 !default;
$transparent-bg: transparent !default;
$light-bg: #f5f6fa !default;
$dark-background: #555555 !default;


$grays: () !default;
$grays: map-merge((
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
), $grays);

$blue: #5e72e4 !default;
$indigo: #5603ad !default;
$purple: #8965e0 !default;
$pink: #f3a4b5 !default;
$red: #df323c !default;
$orange: #f7931a !default;
$yellow: #ffd600 !default;
$green: #3cb054 !default;
$teal: #11cdef !default;
$cyan: #2bffc6 !default;

$epColor: #1f8ef1 !default;
$rpColor: #d048b6 !default;

$memberAllColor: #e27cf1 !default;
$memberAllColorForRegionMaps: #864bfb !default;
$memberEpColor: #d12151 !default;
$memberRpColor: #1382b2 !default;
$memberDefaultGreyColor: #808080 !default;
$memberDefaultWhiteColor: #FFFFFF !default;
$memberDefaultDarkGrayColor: #2b2b2b !default;


$line-height-lg: 1.625rem !default;
$line-height-sm: 1.5 !default;
$line-height: 1.35em !default;


$opacity-gray-3: rgba(222, 222, 222, .3) !default;
$opacity-gray-5: rgba(222, 222, 222, .5) !default;
$opacity-gray-8: rgba(222, 222, 222, .8) !default;


$opacity-5: rgba(255, 255, 255, .5) !default;
$opacity-6: rgba(255, 255, 255, .6) !default;
$opacity-8: rgba(255, 255, 255, .8) !default;


$opacity-1: rgba(255, 255, 255, .1) !default;
$opacity-2: rgba(255, 255, 255, .2) !default;
$opacity-3: rgba(255, 255, 255, .3) !default;

$opacity-black-1: rgba(0, 0, 0, .1) !default;
$opacity-black-2: rgba(0, 0, 0, .2) !default;
$opacity-black-3: rgba(0, 0, 0, .3) !default;
$opacity-black-4: rgba(0, 0, 0, .4) !default;
$opacity-black-5: rgba(0, 0, 0, .5) !default;
$opacity-black-6: rgba(0, 0, 0, .6) !default;
$opacity-black-7: rgba(0, 0, 0, .7) !default;
$opacity-black-8: rgba(0, 0, 0, .8) !default;
$opacity-black-9: rgba(0, 0, 0, .9) !default;

// end beniamin

$colors: () !default;
$colors: map-merge((
        "blue": $blue,
        "indigo": $indigo,
        "purple": $purple,
        "pink": $pink,
        "red": $red,
        "orange": $orange,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "cyan": $cyan,
        "white": $white,
        "gray": $gray-600,
        "light": $gray-400,
        "lighter": $gray-200,
        "gray-dark": $gray-800
), $colors);

$default-color-opacity: rgba(182, 182, 182, .6) !default;
$orange-color: #f96332 !default;

$default: #1e1e2f !default;
$primary: #e14eca !default;
$secondary: #f4f5f7 !default;
$success: #00f2c3 !default;
$info: #1d8cf8 !default;
$warning: #ff8d72 !default;
$danger: #fd5d93 !default;
$black: #222a42 !default;
$ep: #d12250 !default;
$rp: #0488c1 !default;

$topbar-x: topbar-x !default;
$topbar-back: topbar-back !default;
$bottombar-x: bottombar-x !default;
$bottombar-back: bottombar-back !default;

// gradient
$default-states: #263148 !default;
$primary-states: #ba54f5 !default;
$success-states: #0098f0 !default;
$info-states: #3358f4 !default;
$warning-states: #ff6491 !default;
$danger-states: #ec250d !default;
$black-states: #1d253b !default;
$all-states: #9966fc !default;
$all-states-for-region-maps: #864bfb !default;
$ep-states: #9a183b !default;
$rp-states: #0e5f81 !default;
$member-dark-gray-states: #171717 !default;

$background-black: #1e1e2f !default;
$background-states-black: #1e1e24 !default;

// opacity
$default-opacity: rgba(182, 182, 182, .6) !default;
$primary-opacity: rgba(249, 99, 50, .3) !default;
$success-opacity: rgba(24, 206, 15, .3) !default;
$info-opacity: rgba(44, 168, 255, .3) !default;
$warning-opacity: rgba(255, 178, 54, .3) !default;
$danger-opacity: rgba(255, 54, 54, .3) !default;

$light-gray: #E3E3E3 !default;
$medium-gray: #DDDDDD !default;
$dark-gray: #9A9A9A !default;
$card-stats-gray: #cdcdcd !default;
$active-gray: #777777 !default;
$nav-gray: #444444 !default;
$search-gray: #b7b7b7 !default;
$calendar-gray: #9499a7 !default;
$active-blue: #00bbff !default;

$light: $gray-500 !default;
$dark: $gray-900 !default;
$darker: darken($gray-900, 15%) !default;

// Body

$body-bg: $background-black !default;
$body-color: $gray-700 !default;

// Spacing

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3),
        'sm': ($spacer * 2),
        'md': ($spacer * 4),
        'lg': ($spacer * 6),
        'xl': ($spacer * 8)
), $spacers);

$padding-base-vertical: .5rem !default;
$padding-base-horizontal: .7rem !default;

$padding-btn-vertical: 11px !default;
$padding-btn-horizontal: 40px !default;

$padding-round-horizontal: 23px !default;

$padding-lg-vertical: 15px !default;

$padding-input-vertical: 11px !default;
$padding-input-horizontal: 19px !default;


$margin-lg-vertical: 30px !default;
$margin-base-vertical: 15px !default;

// This variable affects the `.h-*` and `.w-*` classes.

$sizes: () !default;
$sizes: map-merge((
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%
), $sizes);

// components

$shape-height-xl: 1.5 !default;
$shape-height-lg: 1.5 !default;
$shape-height-sm: 1.5 !default;

// border
$none: 0 !default;
$border-thin: 1px !default;
$border-thick: 2px !default;
$border-width: .0625rem !default;
$border-color: $gray-200 !default;

$border-radius: .25rem !default;
$border-radius-xl: 0.875rem !default;
$border-radius-lg: 0.4285rem !default;
$border-radius-sm: 0.2857rem !default;
$border-radius-xs: 0.1428rem !default;

$margin-bottom: 10px !default;
$border: 1px solid !default;

$box-shadow-sm: 0 .125rem .25rem rgba($black, .075);
$box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07);
$box-shadow-lg: 0 1rem 3rem rgba($black, .175);

$dropdown-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.125);
$box-shadow-raised: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
$box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);

$component-active-color: $white !default;
$component-active-bg: theme-color("primary") !default;
$component-active-border-color: theme-color("primary") !default;

$component-hover-color: $gray-300 !default;
$component-hover-bg: $gray-300 !default;
$component-hover-border-color: $gray-300 !default;

$caret-width: .3em !default;

$transition-base: all .15s ease !default;
$transition-fade: opacity .15s linear !default;
$transition-collapse: height .35s ease !default;

$fast-transition-time: 150ms !default;

$transition-linear: linear !default;
$transition-bezier: cubic-bezier(0.34, 1.61, 0.7, 1) !default;
$transition-ease: ease 0s;

$select-coordinates: 50% -40px !default;
$general-transition-time: 300ms !default;

// Fonts

$font-family-base: 'Poppins', sans-serif !default;
$font-family-alt: 'Poppins', sans-serif !default;

$font-size-dash: .875rem !default;

$font-size-base: $font-size-dash !default; // Assumes the browser default, typically `16px`
$font-size-xl: ($font-size-base * 1.428);
$font-size-lg: ($font-size-base * 1.142);
$font-size-sm: ($font-size-base * .85714286);
$font-size-xs: ($font-size-base * .714);

$h1-font-size: $font-size-base * 2.35714285 !default; //2.1875rem
$h2-font-size: $font-size-base * 1.9285714 !default;
$h3-font-size: $font-size-base * 1.642857 !default;
$h4-font-size: $font-size-base * 1.2142857 !default;
$h5-font-size: $font-size-base * 0.9285714 !default;
$h6-font-size: $font-size-base * 0.8571428 !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;
$font-weight-extra-bold: 700 !default;

$font-paragraph: 1rem !default;

$font-weight-base: $font-weight-normal !default;
$shape-height-base: 1.428571 !default;

$headings-margin-bottom: ($spacer / 2);
$headings-font-family: inherit !default;
$headings-font-weight: $font-weight-normal !default;
$headings-line-height: 1.2 !default;
$headings-color: $gray-800 !default;

$heading-letter-spacing: .025em !default;
$heading-font-size: .95rem !default;
$heading-text-transform: uppercase !default;
$heading-font-weight: $headings-font-weight !default;

$heading-title-letter-spacing: .025em !default;
$heading-title-font-size: 1.375rem !default;
$heading-title-font-weight: $font-weight-bold !default;
$heading-title-text-transform: uppercase !default;

$heading-section-letter-spacing: .025em !default;
$heading-section-font-size: 1.375rem !default;
$heading-section-font-weight: $font-weight-bold !default;
$heading-section-text-transform: uppercase !default;

$display1-size: 3.3rem !default;
$display2-size: 2.75rem !default;
$display3-size: 2.1875rem !default;
$display4-size: 1.6275rem !default;

$display1-weight: $font-weight-bold !default;
$display2-weight: $font-weight-bold !default;
$display3-weight: $font-weight-bold !default;
$display4-weight: $font-weight-bold !default;
$display-line-height: $headings-line-height !default;

$paragraph-font-size: 0.625rem !default;
$paragraph-font-weight: 300 !default;
$paragraph-line-height: 1.7 !default;

$lead-font-size: ($paragraph-font-size * 1.25);
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-600 !default;

$blockquote-small-color: $gray-600 !default;
$blockquote-font-size: ($font-size-base * 1.1);

$hr-border-color: rgba($black, .1);
$hr-border-width: $border-width !default;

$mark-padding: .2em !default;

$dt-font-weight: $font-weight-bold !default;

$list-inline-padding: .5rem !default;

$mark-bg: #fcf8e3 !default;

$hr-margin-y: $spacer * 2 !default;

// Icons

$icon-size: 2.375rem !default;
$icon-size-regular: 2.375rem !default;
$icon-font-size-regular: 0.9375rem !default;
$icon-font-size-sm: 0.6875rem !default;
$icon-size-sm: 1.875rem !default;
$icon-font-size-lg: 1.325rem !default;
$icon-size-lg: 3.6rem !default;
$icon-size-xl: 5rem !default;

// Buttons + Forms

$input-btn-padding-y: .5rem !default;
$input-btn-padding-x: .7rem !default;
$input-btn-line-height: $shape-height-base !default;

$input-btn-focus-width: 0 !default;
$input-btn-focus-color: rgba($component-active-bg, 1);
//$input-btn-focus-box-shadow:  0 4px 10px 0 rgba(0, 0, 0, 0.04);
$input-btn-focus-box-shadow: none !default;

$input-btn-padding-y-sm: .25rem !default;
$input-btn-padding-x-sm: .5rem !default;
$input-btn-line-height-sm: 1.35 !default;

$input-btn-padding-y-lg: .875rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-line-height-lg: 1.35 !default;

$input-btn-border-width: 1px !default;

$input-btn-font-size-sm: .75rem !default;
$input-btn-font-size: .875rem !default;
$input-btn-font-size-lg: .875rem !default;

$input-padding-vertical: 11px !default;
$input-padding-horizontal: 19px !default;

$input-height: calc(2.25rem + 2px);
$small-input-height: calc(1.25rem + 2px);

// Buttons

$btn-padding-y: 11px !default;
$btn-padding-x: 40px !default;
$btn-line-height: $line-height !default;

$btn-padding-y-sm: 5px !default;
$btn-padding-x-sm: 15px !default;
$btn-line-height-sm: $input-btn-line-height-sm !default;

$btn-padding-y-lg: 15px !default;
$btn-padding-x-lg: 48px !default;
$btn-line-height-lg: $input-btn-line-height-lg !default;

$btn-border-width: $input-btn-border-width !default;

$btn-font-size-lg: 1em !default;
$btn-font-weight: 600 !default;
$btn-text-transform: uppercase !default;
$btn-letter-spacing: .025em !default;
$btn-box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
$btn-hover-box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
$btn-focus-box-shadow: $btn-hover-box-shadow !default;
$btn-focus-width: $input-btn-focus-width !default;


$btn-active-box-shadow: none !default;
$btn-round-radius: 30px !default;


// Navbar

$navbar-transition: all .15s linear !default;
$navbar-padding-y: .625rem !default;
$navbar-padding-x: .9375rem !default;

$navbar-nav-link-padding-x: 1rem !default;
$navbar-nav-link-padding-y: 1rem !default;

$navbar-nav-link-font-family: $font-family-alt !default;
$navbar-nav-link-font-size: .9rem !default;
$navbar-nav-link-font-weight: 400 !default;
$navbar-nav-link-text-transform: normal !default;
$navbar-nav-link-letter-spacing: 0 !default;
$navbar-nav-link-border-radius: $border-radius !default;

/* navbar color */
$navbar-color: #1a1e34 !default;
$navbar-dark-bg: transparent !default;
$navbar-dark-hover-bg: rgba(255, 255, 255, .1);
$navbar-dark-active-bg: rgba(255, 255, 255, .1);
$navbar-dark-color: rgba($white, .95);
$navbar-dark-hover-color: rgba($white, .65);
$navbar-dark-active-color: rgba($white, .65);
$navbar-dark-disabled-color: rgba($white, .25);
$navbar-dark-toggler-border-color: transparent !default;

$navbar-light-bg: transparent !default;
$navbar-light-hover-bg: rgba(0, 0, 0, .1);
$navbar-light-active-bg: rgba(0, 0, 0, .1);
$navbar-light-color: rgba($black, .5);
$navbar-light-hover-color: rgba($black, .7);
$navbar-light-active-color: rgba($black, .9);
$navbar-light-disabled-color: rgba($black, .3);
$navbar-light-toggler-icon-bg: str-replace(url("data:image/svg+xml !default;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-light-toggler-border-color: transparent !default;
$full-width: 100% !default;
$navbar-padding: 10px 15px !default;

// Tables

$table-cell-padding: 1rem !default;
$table-cell-padding-sm: .3rem !default;

$table-bg: transparent !default;
$table-accent-bg: rgba($black, .05);
$table-hover-bg: rgba($black, .075);
$table-active-bg: $table-hover-bg !default;

$table-border-width: $border-width !default;
$table-border-color: $gray-300 !default;

$table-head-bg: $gray-200 !default;
$table-head-color: $gray-700 !default;

$table-dark-bg: $gray-900 !default;
$table-dark-accent-bg: rgba($white, .05);
$table-dark-hover-bg: rgba($white, .075);
$table-dark-border-color: lighten($gray-900, 7.5%);
$table-dark-color: $body-bg !default;

// Sidebar

$sidebar-width: 300px;
$sidebar-transition: .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
$sidebar-box-shadow: 0px 2px 22px 0 rgba(0, 0, 0, .20), 0px 2px 30px 0 rgba(0, 0, 0, .35);

// Footer

$footer-link-font-size: .85rem !default;
$footer-bg: theme-color("secondary") !default;
$footer-color: $gray-600 !default;
$footer-link-color: $gray-600 !default;
$footer-link-hover-color: $gray-700 !default;
$footer-heading-color: $gray-600 !default;
$footer-heading-font-size: $font-size-sm !default;

// Cards

$card-spacer-y: 1.25rem !default;
$card-spacer-x: 1.5rem !default;
$card-border-width: $border-width !default;
$card-border-radius: $border-radius-sm !default;
$card-border-color: rgba($black, .05);
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg: $gray-100 !default;
$card-bg: $white !default;
$card-black-background: rgba(18, 18, 18, 0.9);
$card-img-overlay-padding: 1.25rem !default;

//$card-group-margin:                 ($grid-gutter-width / 2);
//$card-deck-margin:                  $card-group-margin !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;
