.card {
  background: $card-black-background!important;
  border: 0;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  box-shadow: $box-shadow;
  transition: all .5s;

  &.collapsed {
    //height: 100px;
  }

  label {
    color: rgba($white, 0.6);
  }

  .card-title {
    margin-bottom: .75rem;
  }

  .card-body {
    padding: 5px;
    max-width: 100%;

    &.table-full-width {
      padding-left: 0;
      padding-right: 0;
    }

    .card-title {
      color: $white;
      text-transform: inherit;
      font-weight: $font-weight-light;
      margin-bottom: .75rem;
    }

    .card-description, .card-category {
      color: rgba($white, 0.6);
    }
  }

  &.collapsed {
    .card-body {
      opacity: 0;
      max-height: 0;
    }
  }

  .card-header {
    &:not([data-background-color]) {
      background-color: transparent;
    }

    padding: 15px 15px 0;
    border: 0;
    color: rgba($white, 0.8);

    .card-title {
      color: $white;
      font-weight: 100;

      &.card-title-primary {
        color: $primary;
      }

      &.card-title-success {
        color: $cyan;
      }

      &.card-title-warning {
        color: $warning;
      }

      &.card-title-ep {
        color: $epColor;
      }

      &.card-title-rp {
        color: $rpColor;
      }
    }

    .card-category {
      color: $dark-gray;
      margin-bottom: 5px;
      font-weight: 300;
    }
  }

  .map {
    border-radius: $border-radius-sm;

    &.map-big {
      height: 420px;
    }
  }

  &.card-white {
    background: $white;

    .card-title {
      color: $black;
    }

    .card-category, .stats {
      color: $card-stats-gray;
    }

    //style for inputs

    @include form-control-placeholder(rgba($black, 0.4), 1);

    .has-danger {
      .form-control, .input-group-prepend .input-group-text {
        border-color: $danger-states;
      }
    }

    .input-group-prepend .input-group-text {
      border-color: rgba($black-states, 0.2);
      color: $black-states;
    }

    .form-control {
      color: $black;
      border-color: rgba($black-states, 0.2);

      &:focus {
        border-color: $primary;
      }
    }

    label:not(.btn) {
      color: $default;
    }

    .form-group.no-border,
    .input-group.no-border {
      .form-control,
      .form-control + .input-group-prepend .input-group-text,
      .form-control + .input-group-append .input-group-text,
      .input-group-prepend .input-group-text,
      .input-group-append .input-group-text {
        background-color: $opacity-gray-3;

        &:focus,
        &:active,
        &:active {
          background-color: $opacity-gray-5;
        }
      }

      .form-control {
        &:focus {
          & + .input-group-prepend .input-group-text,
          & + .input-group-append .input-group-text {
            background-color: $transparent-bg;
          }
        }
      }
    }

    .input-group[disabled] {
      .input-group-prepend .input-group-text,
      .input-group-append .input-group-text {
        background-color: $black;
      }
    }

    .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
      background: $light-gray;
      border-color: rgba($black-states, 0.3);
    }

    .input-group-focus {
      .input-group-prepend .input-group-text,
      .input-group-append .input-group-text,
      .form-control {
        background-color: $white;
        border-color: $primary;
      }

      &.no-border {
        .input-group-prepend .input-group-text,
        .input-group-append .input-group-text {

          background-color: $opacity-gray-5;
        }
      }
    }

    .input-group-prepend .input-group-text {
      border-right: none;
    }

    .input-group-append .input-group-text {
      border-left: none;
    }

    .has-danger .form-control:focus, .has-success.input-group-focus .input-group-append .input-group-text, .has-success.input-group-focus .input-group-prepend .input-group-text {
      border-color: $danger-states;
    }

    .has-success .form-control:focus, .has-success.input-group-focus .input-group-append .input-group-text, .has-success.input-group-focus .input-group-prepend .input-group-text {
      border-color: darken($success, 10%);
    }
  }

  &.card-plain {
    background: transparent;
    box-shadow: none;
  }

  .image {
    overflow: hidden;
    height: 200px;
    position: relative;
  }

  .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 15px;
  }

  label {
    font-size: $font-size-sm;
    margin-bottom: 5px;
  }

  .card-footer {
    background-color: transparent;
    border: 0;
    padding: 15px;


    .stats {
      i {
        margin-right: 5px;
        position: relative;
      }
    }

    h6 {
      margin-bottom: 0;
      padding: 7px 0;
    }
  }
}

.card-body {
  padding: $card-spacer-y;
}

.loading {
  background: url("../../img/PG_LoaderAnimation_White.gif") center center no-repeat;
  opacity: 0.7;
  background-size: clamp(10px,30%,200px) auto;
  position: relative;
}

.loading.overlay::before {
  content:"";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  z-index: 10;
}

.filter-card {
  .card-title {
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
    font-weight: normal;
  }
}

.table-card {
  .card-title {
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
  }
  
  nav {
    display: flex;
  }
}

@media screen and (max-width: 1920px) {
  .chart-summary-responsive-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 1366px) {
  .chart-summary-responsive-text {
    font-size: 12px;
  }
}

.chart-summary-pills {
  display: inline-flex;
  width: 100%;
  justify-content: center;

  .chart-summary-pill {
    padding-bottom: 0.5rem;
    &:not(:first-child) {
      padding: 0 0 0.5rem 0.75rem;
    }
  }
}

.chart-summary-badge-radius .badge:nth-child(1) {
  border-radius: 0.25rem 0 0 0.25rem !important;
  background-color: $white;
  color: $black;
}

.chart-summary-badge-radius .badge:nth-child(2) {
  border-radius: 0 0.25rem 0.25rem 0 !important;
  color: $white;
  @include diagonal-gradient($gray-900, $gray-700);
}

.chart-summary-badge-radius .badge {
  font-weight: 400 !important;
  padding: 3px 6px;
}
