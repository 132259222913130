
@mixin transform-translate-x($value){
  -webkit-transform:  translate3d($value, 0, 0);
  -moz-transform: translate3d($value, 0, 0);
  -o-transform: translate3d($value, 0, 0);
  -ms-transform: translate3d($value, 0, 0);
  transform: translate3d($value, 0, 0);
}

@mixin transform-translate-y($value){
  -webkit-transform:  translate3d(0,$value,0);
  -moz-transform: translate3d(0,$value,0);
  -o-transform: translate3d(0,$value,0);
  -ms-transform: translate3d(0,$value,0);
  transform: translate3d(0,$value,0);
}

@mixin transitions($time, $type){
  -webkit-transition: all $time $type;
  -moz-transition: all $time $type;
  -o-transition: all $time $type;
  -ms-transition: all $time $type;
  transition: all $time $type;
}

@mixin diagonal-gradient($start-color, $end-color){
  background: $start-color;
  background-image: -webkit-linear-gradient(to bottom left, $start-color, $end-color, $start-color);
  background-image: -o-linear-gradient(to bottom left, $start-color, $end-color, $start-color);
  background-image: -moz-linear-gradient(to bottom left, $start-color, $end-color, $start-color);
  background-image: linear-gradient(to bottom left, $start-color, $end-color, $start-color);
  background-size: 210% 210%;
  background-position: top right;
}

@mixin bar-animation($type){
  -webkit-animation: $type 500ms linear 0s;
  -moz-animation: $type 500ms linear 0s;
  animation: $type 500ms 0s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@mixin rotate-180(){
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

@mixin transform-translate-y-dropdown($value) {
  -webkit-transform:  translate3d(0,$value,0) !important;
  -moz-transform: translate3d(0,$value,0) !important;
  -o-transform: translate3d(0,$value,0) !important;
  -ms-transform: translate3d(0,$value,0) !important;
  transform: translate3d(0,$value,0) !important;
}

@mixin linear-gradient($color, $states-color){
  background: $color;
  background: -webkit-linear-gradient(0deg, $color 0%, $states-color 100%);
  background: -o-linear-gradient(0deg, $color 0%, $states-color 100%);
  background: -moz-linear-gradient(0deg, $color 0%, $states-color 100%);
  background: linear-gradient(0deg, $color 0%, $states-color 100%);
}

@mixin linear-gradient-right($color, $states-color){
  background: $color;
  background: -webkit-linear-gradient(to right, $color 0%, $states-color 100%);
  background: -o-linear-gradient(to right, $color 0%, $states-color 100%);
  background: -moz-linear-gradient(to right, $color 0%, $states-color 100%);
  background: linear-gradient(to right, $color 0%, $states-color 100%);
}

@mixin sidebar-color($color){
  &:after{
    background: $color;
  }

  .nav li.active > a{
    color: $color;

    i{
      color: $color;
    }
  }
}

@mixin transition-input-focus-color() {
  -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -ms-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

@mixin topbar-x-rotation(){
  @keyframes topbar-x {
    0% {top: 0; transform: rotate(0deg); }
    45% {top: 6px; transform: rotate(145deg); }
    75% {transform: rotate(130deg); }
    100% {transform: rotate(135deg); }
  }
  @-webkit-keyframes topbar-x {
    0% {top: 0; -webkit-transform: rotate(0deg); }
    45% {top: 6px; -webkit-transform: rotate(145deg); }
    75% {-webkit-transform: rotate(130deg); }
    100% { -webkit-transform: rotate(135deg); }
  }
  @-moz-keyframes topbar-x {
    0% {top: 0; -moz-transform: rotate(0deg); }
    45% {top: 6px; -moz-transform: rotate(145deg); }
    75% {-moz-transform: rotate(130deg); }
    100% { -moz-transform: rotate(135deg); }
  }
}

@mixin topbar-back-rotation(){
  @keyframes topbar-back {
    0% { top: 6px; transform: rotate(135deg); }
    45% { transform: rotate(-10deg); }
    75% { transform: rotate(5deg); }
    100% { top: 0; transform: rotate(0); }
  }

  @-webkit-keyframes topbar-back {
    0% { top: 6px; -webkit-transform: rotate(135deg); }
    45% { -webkit-transform: rotate(-10deg); }
    75% { -webkit-transform: rotate(5deg); }
    100% { top: 0; -webkit-transform: rotate(0); }
  }

  @-moz-keyframes topbar-back {
    0% { top: 6px; -moz-transform: rotate(135deg); }
    45% { -moz-transform: rotate(-10deg); }
    75% { -moz-transform: rotate(5deg); }
    100% { top: 0; -moz-transform: rotate(0); }
  }
}

@mixin bottombar-x-rotation(){
  @keyframes bottombar-x {
    0% {bottom: 0; transform: rotate(0deg);}
    45% {bottom: 6px; transform: rotate(-145deg);}
    75% {transform: rotate(-130deg);}
    100% {transform: rotate(-135deg);}
  }
  @-webkit-keyframes bottombar-x {
    0% {bottom: 0; -webkit-transform: rotate(0deg);}
    45% {bottom: 6px; -webkit-transform: rotate(-145deg);}
    75% {-webkit-transform: rotate(-130deg);}
    100% {-webkit-transform: rotate(-135deg);}
  }
  @-moz-keyframes bottombar-x {
    0% {bottom: 0; -moz-transform: rotate(0deg);}
    45% {bottom: 6px; -moz-transform: rotate(-145deg);}
    75% {-moz-transform: rotate(-130deg);}
    100% {-moz-transform: rotate(-135deg);}
  }
}

@mixin bottombar-back-rotation{
  @keyframes bottombar-back {
    0% { bottom: 6px;transform: rotate(-135deg);}
    45% { transform: rotate(10deg);}
    75% { transform: rotate(-5deg);}
    100% { bottom: 0;transform: rotate(0);}
  }
  @-webkit-keyframes bottombar-back {
    0% {bottom: 6px;-webkit-transform: rotate(-135deg);}
    45% {-webkit-transform: rotate(10deg);}
    75% {-webkit-transform: rotate(-5deg);}
    100% {bottom: 0;-webkit-transform: rotate(0);}
  }
  @-moz-keyframes bottombar-back {
    0% {bottom: 6px;-moz-transform: rotate(-135deg);}
    45% {-moz-transform: rotate(10deg);}
    75% {-moz-transform: rotate(-5deg);}
    100% {bottom: 0;-moz-transform: rotate(0);}
  }

}


@mixin nc-rotate($degrees, $rotation) {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation});
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin nc-flip($horiz, $vert, $rotation) {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation});
  -webkit-transform: scale($horiz, $vert);
  -moz-transform: scale($horiz, $vert);
  -ms-transform: scale($horiz, $vert);
  -o-transform: scale($horiz, $vert);
  transform: scale($horiz, $vert);
}

// Buttons

@mixin btn-styles($btn-color, $btn-states-color) {
  @include diagonal-gradient($btn-color, $btn-states-color);
  background-color: $btn-color;
  transition: all 0.15s ease;
  box-shadow: none;
  &.animation-on-hover:hover{
    background-position: bottom left;
    transition:0.3s ease-in-out;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &:active:hover,
  &.active:focus,
  &.active:hover
  {
    background-color: $btn-states-color !important;
    background-image: linear-gradient(to bottom left, $btn-color, $btn-states-color, $btn-color) !important;
    background-image: -webkit-linear-gradient(to bottom left, $btn-color, $btn-states-color, $btn-color) !important;
    background-image: -o-linear-gradient(to bottom left, $btn-color, $btn-states-color, $btn-color) !important;
    background-image: -moz-linear-gradient(to bottom left, $btn-color, $btn-states-color, $btn-color) !important;
    color: $white;
    box-shadow: none;
  }

  &:active{
    box-shadow: none !important;
    transform: translateY(1px) !important;
    transition: all .15s ease;
  }

  &:not([data-action]):hover{

    box-shadow:  2px 2px 6px rgba(0,0,0,0.4);
    transform: translateY(-1px);
    -webkit-transform: translateY(-1px);
  }

  &.disabled,
  &:disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $btn-color;
      border-color: $btn-color;
    }
  }

  // btn-neutral style
  @if $btn-color == $white{
    color: $primary;

    &.btn-danger{
      color: $danger;

      &:hover,
      &:focus,
      &:active,
      &:active:focus{
        color: $danger-states;
      }
    }

    &.btn-info{
      color: $info;

      &:hover,
      &:focus,
      &:active,
      &:active:focus{
        color: $info-states;
      }
    }

    &.btn-warning{
      color: $warning;

      &:hover,
      &:focus,
      &:active,
      &:active:focus{
        color: $warning-states;
      }
    }

    &.btn-success{
      color: $success;

      &:hover,
      &:focus,
      &:active,
      &:active:focus{
        color: $success-states;
      }
    }

    &.btn-default{
      color: $default;

      &:hover,
      &:focus,
      &:active,
      &:active:focus{
        color: $default-states;
      }
    }

    &.active,
    &:active,
    &:active:focus,
    &:active:hover,
    &.active:focus,
    &.active:hover,
    .show > &.dropdown-toggle,
    .show > &.dropdown-toggle:focus,
    .show > &.dropdown-toggle:hover {
      background-color: $white;
      color: $primary-states;
      box-shadow: none;
    }

    &:hover,
    &:focus{
      color: $primary-states;

      &:not(.nav-link){
        box-shadow: none;
      }

    }

  } @else {
    color: $white;
  }

  &.btn-simple{
    color: $btn-color;
    border-color: $btn-color;
    background: $transparent-bg;

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active{
      color: $btn-color;
      border-color: $btn-color;
      background-color: $transparent-bg !important;
      background-image: none !important;
      box-shadow: none;
    }

    &.active{
      border-color: $btn-color !important;
      &:hover,
      &:focus,
      &:active,
      &:not(:disabled):not(.disabled):active{
        color: $white;
        border-color: $btn-color;
        background-image: linear-gradient(to bottom left, $btn-color, $btn-states-color, $btn-color) !important;
        background-image: -webkit-linear-gradient(to bottom left, $btn-color, $btn-states-color, $btn-color) !important;
        background-image: -o-linear-gradient(to bottom left, $btn-color, $btn-states-color, $btn-color) !important;
        background-image: -moz-linear-gradient(to bottom left, $btn-color, $btn-states-color, $btn-color) !important;
        background-color: $btn-states-color !important;
        box-shadow: none;
      }
    }
  }

  &.btn-link{
    color: $btn-color;
    &:hover,
    &:focus,
    &:active{
      background-color: $transparent-bg !important;
      background-image: none !important;
      color: $white !important;
      text-decoration: none;
      box-shadow: none;
    }
  }
}

@mixin button-variant($background, $border, $hover-background: darken($background, 0%), $hover-border: darken($border, 0%), $active-background: darken($background, 10%), $active-border: darken($border, 0%)) {
  color: color-yiq($background);
  @include gradient-bg($background);
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  @include hover {
    color: color-yiq($hover-background);
    @include gradient-bg($hover-background);
    border-color: $hover-border;
  }

  &:focus,
  &.focus {
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($border, .5);
    }
    @else {
      box-shadow: 0 0 0 $btn-focus-width rgba($border, .5);
    }
  } // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: color-yiq($background);
    background-color: $background;
    border-color: $border;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show>&.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }
    border-color: $active-border;

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($border, .5);
      }
      @else {
        box-shadow: 0 0 0 $btn-focus-width rgba($border, .5);
      }
    }
  }
}

@mixin button-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
  color: $color;
  background-color: transparent;
  background-image: none;
  border-color: $color;

  &:hover {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show>&.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows and $btn-active-box-shadow !=none {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5);
      }
      @else {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
      }
    }
  }
}

// Button sizes
@mixin btn-size($padding-vertical, $padding-horizontal, $font-size, $border){
  font-size: $font-size;
  border-radius: $border;
  padding: $padding-vertical $padding-horizontal;

  &.btn-simple{
    padding: $padding-vertical - 1 $padding-horizontal - 1;
  }

}

// for social buttons
@mixin social-buttons-color ($color, $state-color){
  @include diagonal-gradient($color, darken($color,20%));
  //background-color: $color;
  color: $white;
  background-size: 210% 210%;
  background-position: top right;
  background-repeat: space;

  &:focus,
  &:active,
  &:hover{
    background-color: $state-color;
    background-image: linear-gradient(to bottom left, $color, darken($color,20%), $color) !important;
    background-image: -webkit-linear-gradient(to bottom left, $color, darken($color,20%), $color) !important;
    background-image: -o-linear-gradient(to bottom left, $color, darken($color,20%), $color) !important;
    background-image: -moz-linear-gradient(to bottom left, $color, darken($color,20%), $color) !important;
    color: $white;
  }

  &.btn-simple{
    color: $state-color;
    background-color: $transparent-bg;
    background-image: none !important;
    box-shadow: none;
    border-color: $state-color;

    &:hover,
    &:focus,
    &:active{
      color: $state-color;
      border-color: $state-color;
    }
  }

  &.btn-neutral{
    color: $color;
    background-color: $white;


    &:hover,
    &:focus,
    &:active{
      color: $state-color;
    }
  }
}

// Opacity

@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  filter: #{alpha(opacity=$opacity-ie)};
}

// Box Shadow

@mixin box-shadow($shadow...) {
  @if $enable-shadows {
    $result: ();

    @if (length($shadow) == 1) {
      // We can pass `@include box-shadow(none);`
      $result: $shadow;
    } @else {
      // Filter to avoid invalid properties for example `box-shadow: none, 1px 1px black;`
      @for $i from 1 through length($shadow) {
        @if nth($shadow, $i) != "none" {
          $result: append($result, nth($shadow, $i), "comma");
        }
      }
    }
    @if (length($result) > 0) {
      box-shadow: $result;
    }
  }
}

// The font-size & responsive-font-size mixin uses RFS to rescale font sizes
@mixin font-size($fs, $important: false) {
  @include rfs($fs, $important);
}

@mixin responsive-font-size($fs, $important: false) {
  @include rfs($fs, $important);
}

// Inputs

@mixin input-size($padding-vertical, $padding-horizontal){
  padding: $padding-vertical $padding-horizontal;
}

@mixin form-control-placeholder($color, $opacity){
  .form-control::-moz-placeholder{
    color: $color;
    @include opacity(1);
  }
  .form-control:-moz-placeholder{
    color: $color;
    @include opacity(1);
  }
  .form-control::-webkit-input-placeholder{
    color: $color;
    @include opacity(1);
  }
  .form-control:-ms-input-placeholder{
    color: $color;
    @include opacity(1);
  }
}

@mixin placeholder() {
  &::-moz-placeholder {@content; } // Firefox
  &:-ms-input-placeholder {@content; } // Internet Explorer 10+
  &::-webkit-input-placeholder  {@content; } // Safari and Chrome
}

@mixin light-form(){
  border-radius: 0;
  border:0;
  padding: 0;
  background-color: transparent;

}


@mixin form-control-lg-padding($padding-vertical, $padding-horizontal) {
  .form-group.no-border.form-control-lg,
  .input-group.no-border.form-control-lg{
    .input-group-append .input-group-text{
      padding: $padding-vertical 0 $padding-vertical $padding-horizontal;
    }

    .form-control{
      padding: $padding-vertical $padding-horizontal;

      & + .input-group-prepend .input-group-text,
      & + .input-group-append .input-group-text{
        padding: $padding-vertical $padding-horizontal $padding-vertical 0;
      }


    }
  }

  .form-group.form-control-lg,
  .input-group.form-control-lg{
    .form-control{
      padding: $padding-vertical - 1 $padding-horizontal - 1;
      height: 100%;

      & + .input-group-prepend .input-group-text,
      & + .input-group-append .input-group-text{
        padding: $padding-vertical - 1 $padding-horizontal - 1 $padding-vertical - 1 0;
      }
    }

    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text{
      padding: $padding-vertical - 1 0 $padding-vertical $padding-horizontal - 1;

      & + .form-control{
        padding: $padding-vertical  $padding-horizontal - 1 $padding-vertical $padding-horizontal - 3;
      }
    }
  }
}



@mixin input-base-padding($padding-vertical, $padding-horizontal) {
  .form-group.no-border,
  .input-group.no-border{
    .form-control{
      padding: $padding-vertical $padding-horizontal;

      & + .input-group-prepend .input-group-text,
      & + .input-group-append .input-group-text{
        padding: $padding-vertical $padding-horizontal $padding-vertical 0;
      }
    }

    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text{
      padding: $padding-vertical 0 $padding-vertical $padding-horizontal;
    }
  }

  .form-group,
  .input-group{
    .form-control{
      padding: $padding-vertical - 1 $padding-horizontal - 1 $padding-vertical - 1 $padding-horizontal - 1;

      & + .input-group-prepend .input-group-text,
      & + .input-group-append .input-group-text{
        padding: $padding-vertical - 1 $padding-horizontal - 1 $padding-vertical - 1 0;
      }
    }

    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text{
      padding: $padding-vertical - 1 0 $padding-vertical - 1 $padding-horizontal - 1;

      & + .form-control,
      & ~ .form-control{
        padding:$padding-vertical - 1 $padding-horizontal $padding-vertical $padding-horizontal - 3;
      }
    }
  }
}


//color1 = $opacity-5
//color2 = $opacity-8
//color3 = $white-color
//color4 = $transparent-bg
//color5 = $opacity-1
//color6 = $opacity-2


@mixin input-coloured-bg($color1, $color2, $color3, $color4, $color5, $color6) {
  @include form-control-placeholder(rgba($white, 0.4), 1);

  .form-control{
    border-color: $color1;
    color: $color2;

    &:focus{
      border-color: $color3;
      background-color: $color4;
      color: $color3;
    }
  }

  .has-success,
  .has-danger{
    &:after{
      color: $color3;
    }
  }

  .has-danger{
    .form-control{
      background-color: $color4;
    }
  }

  .input-group-prepend{
    margin-right: 0;
  }

  .input-group-prepend .input-group-text,
  .input-group-append .input-group-text{
    background-color: rgba($background-black, 0.2);
    border-color: $color1;
    color: $color2;


  }

  .input-group-focus{
    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text{
      background-color: rgba($background-black, 0.3);
      border-color: $color3;
      color: $color3;
    }
  }

  .form-group.no-border,
  .input-group.no-border{
    .form-control{
      background-color: rgba($background-black,0.2);
      color: $color2;

      &:focus,
      &:active,
      &:active{
        background-color: rgba($background-black,0.3);
        color: $color3;
      }
    }

    .form-control + .input-group-prepend .input-group-text,
    .form-control + .input-group-append .input-group-text{
      background-color: rgba($background-black,0.2);;

      &:focus,
      &:active,
      &:active{
        background-color: rgba($background-black,0.3);
        color: $color3;
      }
    }

    .form-control{
      &:focus{
        & + .input-group-prepend .input-group-text,
        & + .input-group-append .input-group-text{
          background-color: rgba($background-black, 0.3);
          color: $color3;
        }
      }
    }

    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text{
      background-color: rgba($background-black, 0.2);
      border: none;
      color: $color2;
    }

    &.input-group-focus{
      .input-group-prepend .input-group-text,
      .input-group-append .input-group-text{
        background-color: rgba($background-black, 0.3);
        color: $color3;
      }
    }
  }
}

