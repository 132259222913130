.horizontal-scroll {
  overflow-x: auto;
}

.pricing-table-headers {
  font-size: 10px;
  padding-top: 10px;

  &.padding-bottom {
    padding-top: 55px;
  }

  div:nth-child(odd) {
    background-color: rgba(51, 51, 51, 0.3) !important;
  }

  div:nth-child(even) {
    background-color: rgba(211, 211, 211, 0.01) !important;
  }
}

.card-header {
  .react-select {
    .react-select__control {
      height: $small-input-height;
    }
  }
}

.time-value-filter-container {
  position: relative;
  padding-bottom: 5px;

  .time-value-filter {
    position: absolute;
    background-color: $card-black-background;
    border-radius: $border-radius;
    border: 1px solid $border-color;
    padding: 0 5px;
    z-index: 1000;
    width: 250px;

    .time-filter-pill {
      font-size: .6rem;
      background-color: $white;
      color: $black;
      margin-right: 3px;
      padding: 0 5px;
      border-radius: $border-radius;
    }

    .time-filter-label {
      font-size: .6rem;
    }

    i {
      padding: 0;
    }

    .time-key-checkbox-container {
      opacity: 1;
      display: none;
      flex-direction: row;
      flex-wrap: wrap;
      border-top: 1px solid $white;
      padding: 10px;
      background-color: $table-dark-bg;

      &.is-open {
        display: flex;
      }

      .time-key-checkbox {
        cursor: pointer;
        flex: 0 0 50%;

        span {
          font-size: 0.75rem;
        }

        &:hover {
          background-color: $table-hover-bg;
        }
      }
    }
  }
}

.variable-filter-container {
  position: relative;
  width: 200px;
  background-color: $card-black-background;
  border-radius: $border-radius;
  border: 1px solid $border-color;
  z-index: 1001;
  font-size: .75rem;

  &.is-open {
    border-radius: $border-radius $border-radius 0 0;
    border-bottom: none;
  }

  .variable-filter-select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;
  }

  .variable-filter-options {
    position: absolute;
    display: none;
    width: 100%;
    border: 1px solid $border-color;

    &.is-open {
      background-color: $table-dark-bg;
      border-top: none;
      border-radius: 0 0 $border-radius $border-radius;
      display: flex;
      flex-direction: column;
    }

    .variable-filter-option {
      padding: 5px;
      cursor: pointer;

      &:hover {
        background-color: $table-hover-bg;
      }
    }
  }
}
