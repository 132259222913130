.collapse-container {
    overflow: hidden;
}

.collapse.show .collapse-container {
    overflow: visible;
}

@media screen and (max-width: 601px) {
    .responsive-text-size {
        font-size: 15px;
    }
}



.react-select__input {
    color:white!important;
}

.main-chart-max-height {
    max-height: 211px;
}

.full-width-chart-max-height {
    max-height: 350px;
}

@media screen and (min-width: 600px) {
    .transactions-search-bar{
        min-width: 300px
    }
}

.disabled-transactions-table-header-actions {
    pointer-events: none;
    opacity: 0.4;
}

.custom-date-picker{
    color: #FFFFFF!important;
}

.custom-date-picker:focus{
    color: black!important;
}

.custom-header-summary-border{
    /*border: 1px solid white !important;*/
}

.custom-region-border{
    border: 1px solid dimgray;
    border-radius: 10px;
}

.custom-table-hover tbody tr:hover {
    background-color: dimgray;
}

.custom-table-hover thead th{
    border: none;
}

#Refund{
    width: fit-content;
}

.custom-form-input {
    font-family: "Montserrat", sans-serif !important;
    font-size: 1rem !important;
    color: white !important;
    width: 75% !important;
    max-width: 75% !important;
}
.custom-form-input:focus {
    color: black !important;
}

.custom-form-input-error {
    font-family: "Montserrat", sans-serif !important;
    color: red !important;
    padding-top: 0.2rem !important;
}


.custom-input-select input {
    width: 100%;
}

.custom-input-select select {
    width: 100%;
}

.max-width-75 {
    max-width: 75%;
}

.rdtSwitch, .rdtCount  {
    color: #007BFF;
}

.rdtDays {
    color: black;
}