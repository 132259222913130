@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

html {
  overflow-x: hidden;
}

.dashboard-dark-mode {
  @import 'bootstrap/bootstrap';

  @import 'custom/variables';
  @import 'custom/mixins';
  @import 'custom/misc';
  @import 'custom/main-layout';
  @import 'custom/performance-layout';
  @import 'custom/navbar';
  @import 'custom/buttons';
  @import 'custom/card';
  @import 'custom/card-stats';
  @import 'custom/card-chart';
  @import 'custom/forms';
  @import 'custom/inputs';
  @import 'custom/toast';
  @import 'custom/theme';
  @import 'custom/member-layout';
  @import 'custom/dynamic';

  @import 'custom/vendor/plugin-perfect-scrollbar';
  @import 'custom/vendor/plugin-datetimepicker';
  @import 'custom/vendor/plugin-react-select';
  @import 'custom/vendor/plugin-react-datetime';
  @import 'custom/vendor/plugin-react-table';
  @import 'custom/vendor/plugin-react-bootstrap-switch';
  @import 'custom/vendor/plugin-nouislider';

  & {
    overflow-x: hidden;
    background-color: $background-black;
    position: relative;
  }
}

.dashboard-light-mode {
  @import '../css/inter.css';
  @import '../css/material-symbols-outlined-icons.css';
  @import 'react-calendar/dist/Calendar.css';

  @import 'custom/vendor/_plugin-react-calendar';
  @import 'custom/vendor/_plugin-react-tooltip';

  & {
    background-color: white;
  }
}