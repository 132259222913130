.react-calendar {
  .react-calendar__tile--now {
    background-color: #EAEAEA;

    &:hover {
      background-color: #EAEAEA;
      opacity: 0.8;
    }
  }

  .react-calendar__tile--active,
  .react-calendar__tile--rangeStart,
  .react-calendar__tile--rangeEnd {
    background-color: #E8EEFF;
    color: black;

    &:hover,
    &:enabled:hover,
    &:enabled:focus {
      background-color: #E8EEFF;
      opacity: 0.8;
    }
  }

  .react-calendar__month-view__days__day--weekend {
    color: #FB4B4E;
  }
}